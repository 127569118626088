<template>
  <div class="content-box">
    <img class="down-bg" src="@/assets/registeredAgent/down-bg.png" alt="" />
    <div class="btn-box">
      <div class="logo">
        <!-- <img v-if="appLogoUrl" :src="appLogoUrl" alt="" /> -->
        <img src="@/assets/registeredAgent/down-icon.png" alt="" />
      </div>
      <div class="name">联动pay展业版</div>
      <div class="submit-btn" @click="downApp">立即下载APP</div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      appLogoUrl: "",
      appDownloadUrl: "",
      appName: "",
    };
  },
  mounted() {},
  methods: {
    /* 判断用户手机为安卓还是iphone */
    checkPhone() {
      // console.log('navigator', navigator)
      let self = this;
      let agent = navigator.userAgent || navigator.vendor || window.opera;
      if (agent != null) {
        let agentName = agent.toLowerCase();
        if (/android/i.test(agentName)) {
          self.isAndroid = true;
          console.log("安卓机", self.isAndroid);
          window.location.href = `http://umfpay.zfmgr.top/api/resource/update/packages/package?appType=1`;
        } else if (/iphone/i.test(agentName)) {
          self.isIOS = true;
          console.log("苹果机", self.isIOS);
          // Toast("暂未开放，敬请期待");
          window.location.href = `https://apps.apple.com/cn/app/ld办公助手/id1622544156`;
        }
      }
    },
    //点击下载应用
    downApp() {
      this.checkPhone();
    },
  },
  beforeCreate() {
    this.$nextTick(() => {
      document.body.setAttribute("style", "background-color:#fff");
    });
  },
  //实例销毁之前钩子--移除body 标签的属性style
  beforeDestroy() {
    document.body.removeAttribute("style");
  },
};
</script>
<style lang="less" scoped>
.content-box {
  position: relative;
  .down-bg {
    width: 100%;
    height: 100%;
  }
  .btn-box {
    position: absolute;
    top: 803px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .logo {
      width: 268px;
      height: 268px;
      // margin-bottom: 44px;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    .name {
      margin-bottom: 66px;
      font-size: 36px;
      height: 66px;
      line-height: 66px;
      color: #333333;
      font-weight: bold;
    }
    .submit-btn {
      width: 560px;
      height: 94px;
      line-height: 94px;
      text-align: center;
      background-color: #ff4741;
      border-radius: 47px;
      font-size: 46px;
      font-weight: bold;
      color: #ffffff;
      // margin-bottom: 165px;
    }
  }
}
</style>
